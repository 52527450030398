.main {
    overflow: hidden;
    max-width: 100%;
    min-height: 100vh;
}

.grid-container {
    padding: 0 20px;

    @include breakpoint(large) {
        padding: 0 30px;
    }
    @include breakpoint(xxxlarge) {
        padding: 0;
    }
}