.add-rows {
    margin-bottom: 20px;

    &__header {
        &cell {
            background-color: map-deep-get($colors, "blue", "web");
            color: map-deep-get($colors, "white", "default");
            border-left: $callout-border;
            border-right: $callout-border;
            height: 40px;
            align-items: center;
            display: inline-flex;
        }
    }

    &__body, &__empty {
        margin-bottom: 20px;
    }

    &__trash {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        height: 40px;

        svg {
            width: 20px;
            height: 20px;

            path {
                fill: map-deep-get($colors, "gray", "dark");
                transition: $transition-default;
            }
        }

        &:hover {
            svg path {
                fill: map-deep-get($colors, "black", "default");
            }
        }
    }

    &__add-button {
        background-color: map-deep-get($colors, "white", "default");
    }
}
