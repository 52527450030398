$step-breadcrumb: (
    "size": 48px,
    "iconSize": 16px,
    "borderWidth": 3px,
    "margin": 25px,
    "green": map-deep-get($colors, "validation", "valid"),
    "red": map-deep-get($colors, "red", "default"),
    "gray": map-deep-get($colors, "gray", "default")
);

.steps-breadcrumb {
    $parent: &;
    text-align: center;
    margin-bottom: map-get($step-breadcrumb, "margin");
    z-index: 5;
    position: relative;
    display: none;

    &--active {
        display: block;
    }

    @include breakpoint(large) {
        display: block;
    }

    &s {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: ((map-get($step-breadcrumb, "size") - map-get($step-breadcrumb, "borderWidth")) / 2);
            left: 0;
            right: 0;
            height: map-get($step-breadcrumb, "borderWidth");
            background-color: map-deep-get($colors, "gray", "dark");
            z-index: 1;
        }
    }

    &__link {
        display: inline-block;
        position: relative;
        color: map-deep-get($colors, "gray", "dark");
        text-decoration: none;
    }

    &__circle {
        width: map-get($step-breadcrumb, "size");
        height: map-get($step-breadcrumb, "size");
        display: block;
        cursor: default;
        margin: 0 auto map-get($step-breadcrumb, "margin") auto;
        transition: $transition-default;
        position: relative;
        text-align: center;
        background-color: map-deep-get($colors, "white", "default");
    }

    &__text, &__number {
        display: block;
        font-size: rem-calc(12);
        font-weight: 700;
    }

    &__number, &__check, &__close {
        width: map-get($step-breadcrumb, "size");
        height: map-get($step-breadcrumb, "size");
        line-height: (map-get($step-breadcrumb, "size") - (map-get($step-breadcrumb, "borderWidth") * 2));
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        border: map-get($step-breadcrumb, "borderWidth") solid map-deep-get($colors, "gray", "dark");
        transition: $transition-default;
    }

    &__text {
        color: map-deep-get($colors, "white", "default");
    }

    &__check, &__close {
        display: none;

        svg {
            width: map-get($step-breadcrumb, "iconSize");
            height: map-get($step-breadcrumb, "iconSize");
            margin: (((map-get($step-breadcrumb, "size") - (map-get($step-breadcrumb, "borderWidth") * 2) ) - map-get($step-breadcrumb, "iconSize") ) / 2) auto 0 auto;

            path {
                fill: map-deep-get($colors, "white", "default");
            }
        }
    }

    &__check {
        background-color: map-get($step-breadcrumb, "green");

        &:hover {
            background-color: darken(map-get($step-breadcrumb, "green"), 10);
        }
    }

    &__close {
        background-color: map-get($step-breadcrumb, "red");

        &:hover {
            background-color: darken(map-get($step-breadcrumb, "red"), 10);
        }
    }

    &--complete {
        #{$parent}__check {
            display: block;
            animation: flip-in-hor-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
            border-color: map-get($step-breadcrumb, "green");
        }

        &, #{$parent}__check, #{$parent}__close, #{$parent}__number {
            cursor: pointer;
        }
    }

    &--errors {
        #{$parent}__close {
            display: block;
            animation: flip-in-hor-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
            border-color: map-get($step-breadcrumb, "red");
        }
    }

    &--disabled {
        #{$parent}__number, #{$parent}__text {
            opacity: 0.3;
        }
    }

    &:first-child::before,
    &:last-child::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 50%;
        top: 0;
        background-color: map-deep-get($colors, "blue", "web");
        z-index: 0;
    }

    &:first-child::before {
        left: 0;
    }

    &:last-child::before {
        right: 0;
    }
}

@keyframes flip-in-hor-bottom {
    0% {
        transform: rotateX(80deg);
        opacity: 0;
    }

    100% {
        transform: rotateX(0);
        opacity: 1;
    }
}