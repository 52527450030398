﻿h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

h1, .h1 {
    //Insert styling here
    font-weight: 500;
    font-size: rem-calc(37);

    @include breakpoint(medium) {
        font-size: rem-calc(42);
    }
}

h2, .h2 {
    //Insert styling here
    font-size: rem-calc(23);
    font-weight: 500;

    @include breakpoint(medium) {
        font-size: rem-calc(28);
    }
}

h3, .h3 {
    //Insert styling here
    font-size: rem-calc(18);
    font-weight: 500;
}

h4, .h4 {
    //Insert styling here
}

h5, .h5 {
    //Insert styling here
}

h6, .h6 {
    //Insert styling here
}

p {
    font-family: $font-text;
    font-weight: 400;
}