.arc {
    padding-bottom: calc(100vh * 0.25);
    position: relative;

    @include breakpoint(large) {
        padding-bottom: 300px;
    }

    svg {
        position: absolute;
        width: 100%;
        height: calc(100vh * 0.25);
        left: 0;
        right: 0;
        bottom: 0;

        @include breakpoint(large) {
            height: 300px;
        }
    }

    &--green {
        background-color: map-deep-get($colors, "green", "light");
    }

    &__container {
        padding: rem-calc(25) 0;

        @include breakpoint(medium) {
            padding: rem-calc(70) 0 0;
        }
    }

    &__heading, &__content {
        color: map-deep-get($colors, "blue", "web");
    }
}
