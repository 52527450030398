[data-tip] {
    position:relative;
    padding-bottom: 10px;

    svg{
        position:absolute;
        bottom: 17px;
        right: 10px;
        height: 25px;
        width: 25px;
    }


}

[data-tip]:after {
    display:none;
    content: attr(data-tip);
    position: absolute;
    top: -42px;
    right: 0;
    padding: 5px 8px;
    background: rgba(26, 26, 26, 0.77);
    color: #fff;
    z-index: 9;
    font-size: .75em;
    line-height: 16px;
    border-radius: 3px;
    width: 65%;
    word-wrap: normal;

    @media(max-width:500px){
        width:100%;

    }
}


[data-tip]:hover:after {
    display:block;
}
