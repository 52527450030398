// Rich text styling layout
/* stylelint-disable */
.rich-text-area {
    .intro {
        font-size: rem-calc(18);
        margin-top: 0;
        margin-bottom: rem-calc(32);
    }

    p {
        margin: 0 0 rem-calc(16);
    }

    h1, h2, h3, h4, h5, h6 {
        margin: rem-calc(32) 0 rem-calc(8);
    }
}

/* stylelint-enable */