.signup-form {
    &__heading {
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: $callout-border;
    }

    &__wrapper {
        background-color: map-deep-get($colors, "blue", "web");
        padding: 50px 0;
    }

    &__container {
        @include callout();

        @include breakpoint(medium) {
            padding-top: 2rem;
            padding-right: 2rem;
            padding-bottom: 2rem;
            padding-left: 2rem;
        }

        @include breakpoint(large) {
            padding-top: 3rem;
            padding-right: 3rem;
            padding-bottom: 3rem;
            padding-left: 3rem;
        }
    }

    &__buttons {
        margin-top: rem-calc(50);

        &--left {
            order: 2;

            @include breakpoint(medium) {
                order: 1;
            }
        }

        &--right {
            order: 1;

            @include breakpoint(medium) {
                order: 2;
            }
        }
    }

    &__icon{
        width: 5px;
        height: 5px;
        background-color: black;
    }

    &__button {
        //background-color: map-deep-get($colors, "white", "default");
        margin-bottom: rem-calc(20);

        background-color: map-deep-get($colors, "yellow", "web");
        color: map-deep-get($colors, "white", "default");
        svg {
            transition: $transition-default;
            path {
                fill: map-deep-get($colors, "white", "default");
            }
        }

        &:hover {
            svg {
                margin-left: 20px;
            }
        }

        &.button--reverse {
            background-color: map-deep-get($colors, "white", "default");
            color: map-deep-get($colors, "black", "default");

            &::before {
                display: none;
            }

            svg {
                path {
                    fill: map-deep-get($colors, "black", "default");
                }
            }
        }
    }

    label {
        font-weight: 500;
    }

    @include breakpoint(large) {
        .form__group {
            @include clearfix();

            label {
                float: left;
                width: 30%;
                margin-top: 9px;
            }

            input, textarea, select, .form__valueWrapper {
                float: right;
                width: 65%;
            }

            .form__invalid-message {
                @include clearfix();
                display: block;
                float: right;
                width: 65%;
            }

            .checkbox-wrapper label,
            .checkbox-wrapper input,
            .form__valueWrapper .form__invalid-message {
                float: none;
                width: auto;
            }
        }
    }

    &__subheading {
        margin-bottom: 10px;
        border-bottom: 1px solid map-deep-get($colors, "gray", "default");
        width: auto;
        position: relative;
    }

    &__subheading:not(:first-child) {
        margin-top: 40px;
    }
}
