.form {
    overflow: hidden;
    transition: $transition-default;
    $form: &;

    &__group-wrapper {
        &--half {
            #{$form}__group:last-of-type {
                margin-bottom: rem-calc(24);
            }

            @include breakpoint(medium) {
                display: flex;
                margin: 0 rem-calc(-8);

                #{$form}__group {
                    margin: 0 rem-calc(8) rem-calc(24);
                    width: 50%;
                }
            }
        }
    }

    &__group {
        margin-bottom: rem-calc(12);

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__invalid-message {
        color: map-deep-get($colors, "validation", "invalid");
    }

    &__label {
        font-weight: 700;
        display: block;
    }

    &--loading {
        opacity: .5;
    }
}