﻿/// <reference path="../_reference.scss" />

*, *::before, *::after {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

html, body {
    margin: 0 auto;
    padding: 0;
    font-family: $font-default;
    max-width: rem-calc(1920);
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

a {
    transition: $transition-default;

    &, &:visited {
        color: map-deep-get($colors, "green", "default");
        text-decoration: underline;
    }

    &:hover {
        color: map-deep-get($colors, "yellow", "default");
        text-decoration: none;
    }
}

b, strong {
    font-weight: 700;
}

::selection {
    background-color: map-deep-get($colors, "gray", "dark");
    color: map-deep-get($colors, "white", "default");
}