a.button, .button {
    padding: 12px 22px;
    min-height: rem-calc(42);
    border: none;
    transition: $transition-default;
    display: inline-flex;
    align-items: center;
    align-content: left;
    cursor: pointer;
    position: relative;
    text-align: left;
    z-index: 1;
    font-weight: 500;
    color: map-deep-get($colors, "black", "default");
    text-decoration: none;
    font-family: $font-default;

    &::before {
        content: " ";
        width: 4px;
        height: 100%;
        top: 0;
        left: 0;
        background-color: map-deep-get($colors, "yellow", "web");
        display: block;
        position: absolute;
        transition: $transition-default;
        z-index: -1;
    }

    &:hover,
    &:focus {
        color: map-deep-get($colors, "white", "default");

        &::before {
            width: 100%;
        }

        svg path {
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &--wide {
        width: 100%;
    }

    &--reverse {
        padding: 12px 22px;
        text-align: right;
        justify-content: flex-end;

        &::before {
            left: auto;
            right: 0;
        }

        svg {
            margin-left: 0;
            margin-right: 10px;
        }
    }

    &--flat {
        &::before {
            display: none;
        }
        background-color: map-deep-get($colors, "gray", "light");
        &:hover {
            background-color: map-deep-get($colors, "yellow", "web");
        }
    }

    svg {
        width: rem-calc(20);
        height: rem-calc(20);
        margin-left: 10px;

        path {
            transition: $transition-default;
        }
    }
}
