$footer-height: (
    "medium": ("bottom": 40px),
);

.footer {
    margin-top: rem-calc(100);

    &__main {
        padding: 50px 0;
        background-color: map-deep-get($colors, "blue", "web");
    }

    &__bottom {
        height: auto;
        background-color: map-deep-get($colors, "gray", "dark");
        @include breakpoint(large) {
            height: map-deep-get($footer-height, "medium", "bottom");
        }
    }

    &__logo {
        svg {
            width: 110px;
            height: 53px;
        }
    }

    &__title, &__text, &__menu {
        font-size: rem-calc(13px);
    }

    &__text, &__menu {
        color: map-deep-get($colors, "gray", "footer");
        font-family: $font-text;

    }

    &__menu a,
    &__text a {
        color: map-deep-get($colors, "gray", "footer");
        text-decoration: none;
        transition: $transition-default;
        &:hover {
            color: map-deep-get($colors, "yellow", "web");
        }
    }

    &__title {
        color: map-deep-get($colors, "white", "default");
        text-transform: uppercase;
        font-weight: 500;
        font-family: $font-default;
        margin: 40px 0 15px 0;

        @include breakpoint(medium) {
            margin: 0 0;
        }
    }

    &__text {
        line-height: rem-calc(26px);
    }

    &__menu {
        line-height: rem-calc(16px);
        padding-top: 20px;

        @include breakpoint(large) {
            padding-top: 0px;
        }

        > li {
            padding: 5px 0 5px 0;

            @include breakpoint(medium) {
                display: inline-block;
                padding: 12px 12px 12px 0;
            }

            @include breakpoint(large) {
                padding-right: 30px;
            }

            > a {
                display: inline-block;
            }
        }
    }

    &__social {
        padding: 20px 0;
        text-align: left;
        margin-left: -10px;


        @include breakpoint(large) {
            text-align: right;
            padding: 0 0;
        }

        &-link {
            display: inline-block;
            height: 40px;
            width: 40px;
            text-align: center;
            padding: 10px;
            svg {
                path {
                    transition: $transition-default;
                    fill: map-deep-get($colors, "gray", "footer");
                }
            }

            &:hover {
                svg {
                    path {
                        fill: map-deep-get($colors, "yellow", "web");
                    }
                }
            }
        }
    }
}
