$header-height: (
    "small": ("top": 0, "main": 100px),
    "medium": ("top": 40px, "main": 100px),
);

.header {
    width: 100%;
    z-index: 10;
    transition: $transition-default;
    margin: 0 auto;
    $header: &;

    &__top {
        //height: map-deep-get($header-height, "small", "top");
        //display: none;
        background-color: map-deep-get($colors, "blue", "web");
        color: map-deep-get($colors, "gray", "footer");
        font-size: rem-calc(13);
        font-family: $font-text;
        line-height: rem-calc(16);

        a {
            color: map-deep-get($colors, "gray", "footer");
            text-decoration: none;
            transition: $transition-default;
            &:hover {
                color: map-deep-get($colors, "yellow", "web");
            }
        }

        @include breakpoint(medium) {
            //height: map-deep-get($header-height, "medium", "top");
            display: block;

        }
    }

    &__main {
        height: map-deep-get($header-height, "small", "main");
        padding: 23px 0;
        @include breakpoint(medium) {
            height: map-deep-get($header-height, "medium", "main");
        }
    }

    &__logo {
        svg {
            width: 110px;
            height: 53px;
        }
    }
}
